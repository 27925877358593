import { ReactComponent as LaggingIco } from 'images/Icons/Sensors/Lagging.svg';
import { ReactComponent as GenericIco } from 'images/Icons/Sensors/GenericIco.svg';
import { ReactComponent as SaltIco } from 'images/Icons/Sensors/Salt.svg';
import { ReactComponent as EdwinIco } from 'images/Icons/Sensors/Edwin.svg';
import { ReactComponent as AresIco } from 'images/Icons/Sensors/Ares.svg';
import { ReactComponent as BreatherIco } from 'images/Icons/Sensors/Breather.svg';
import { getUserFriendlySensorTypeName } from './getUserFriendlySensorTypeName';
import { Tooltip } from '@mui/material';
import { SensorType } from 'models/sensor/models';

export type DevSensorTypeName =
  | 'PerformanceV1'
  | 'PerformanceV2'
  | 'IntelliLube'
  | 'LaggingWear'
  | 'BreatherSensor';

export const getSensorIco = (
  sensorType: DevSensorTypeName | SensorType | string,
  noTooltip?: boolean,
) => {
  const friendlyName: string = getUserFriendlySensorTypeName(sensorType);

  switch (sensorType) {
    case 'Sensor':
    case 'PerformanceV1':
      return (
        <>
          {noTooltip ? (
            <EdwinIco />
          ) : (
            <Tooltip title={friendlyName} arrow>
              <EdwinIco />
            </Tooltip>
          )}
        </>
      );
    case 'PerformanceSensor':
      return (
        <>
          {noTooltip ? (
            <AresIco />
          ) : (
            <Tooltip title={friendlyName} arrow>
              <AresIco />
            </Tooltip>
          )}
        </>
      );
    case 'LaggingWear':
      return (
        <>
          {noTooltip ? (
            <LaggingIco />
          ) : (
            <Tooltip title={friendlyName} arrow>
              <LaggingIco />
            </Tooltip>
          )}
        </>
      );
    case 'IntelliLube':
      return (
        <>
          {noTooltip ? (
            <SaltIco />
          ) : (
            <Tooltip title={friendlyName} arrow>
              <SaltIco />
            </Tooltip>
          )}
        </>
      );
    case 'BreatherSensor':
      return (
        <>
          {noTooltip ? (
            <BreatherIco />
          ) : (
            <Tooltip title={friendlyName} arrow>
              <BreatherIco />
            </Tooltip>
          )}
        </>
      );
    default:
      return <GenericIco />;
  }
};
