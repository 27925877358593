import { Box, Button, Divider } from '@mui/material';
import { FC, useMemo } from 'react';
import DodgeModal from '../../../../../components/DodgeModal/DodgeModal';
import ContentViewer from './ContentViewer';
import { useGetReleaseNote } from 'api';
import { Loading } from 'components';
import { useDeviceSize } from 'shared/responsive';
import { useTranslation } from 'languages';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import ReactDOMServer from 'react-dom/server';
import DodgeLogoForPDF from './DodgeLogoForPDF';

type SelectedReleasNotesModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  selectedPiId: string;
};

const PreviewReleaseNotes: FC<SelectedReleasNotesModalProps> = (props) => {
  const { selectedPiId } = props;

  const { data, isLoading } = useGetReleaseNote(selectedPiId);

  const handleClose = () => {
    props.onClose?.();
  };

  const { isTablet, isPhone } = useDeviceSize();

  const content = useMemo(() => {
    return `<h2> ${data?.name} </h2>` + ` \n ` + data?.contentMobile + ' ' + data?.contentWeb;
  }, [data]);

  const responsiveProps = {
    paddings: isPhone || isTablet ? 0 : '7rem',
    maxHeight: isPhone || isTablet ? '28rem' : '38rem',
    minHeight: isPhone || isTablet ? '28rem' : '38rem',
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      closeButton: translate('modal_close_button'),
      title: translate('optify_release_notes'),
      loading: translate('commissioning_loading_text'),
      download: translate("download_as_pdf")
    };
  }, []);

  return (
    <DodgeModal width='52rem' open={props.isOpen} title={componentText.title} onClose={handleClose}>
      <Box display='flex' flexDirection='column' gap='1rem' pt={3}>
        <Divider sx={{ mb: 1 }} />
        <Box
          display='flex'
          justifyContent='flex-center'
          sx={{
            paddingLeft: responsiveProps.paddings,
            paddingRight: responsiveProps.paddings,
            maxHeight: responsiveProps.maxHeight,
            minHeight: responsiveProps.minHeight,
            overflow: 'scroll',
            overflowX: 'hidden',
          }}
        >
          {isLoading ? <Loading height={'8rem'} /> : <ContentViewer htmlContent={content} />}
        </Box>
      </Box>
      <Box display='flex' width='100%' sx={{ mt: 5 }} justifyContent='space-between'>
        <Button variant='outlined' sx={{ fontWeight: 'bold' }} disabled={isLoading}>
          <PDFDownloadLink
            document={
              <Document>
                <Page wrap={false} style={{ paddingVertical: 16 }}>
                <DodgeLogoForPDF />
                  <Html>
                    {ReactDOMServer.renderToStaticMarkup(
                        <>
                          <div style={{ width: '95%', borderBottom: '1px solid lightgray', margin: 'auto', marginTop: 8, marginBottom: 8 }} />
                          <div style={{ paddingLeft: 48, paddingRight: 48 }}>
                          <ContentViewer htmlContent={content.replaceAll('<p><br></p>', '')} />
                          </div>
                        </>
                    )}
                  </Html>
                </Page>
              </Document>
            }
            fileName={`${data?.name}.pdf`}
          >  {/* @ts-expect-error code is fine, unknown ts error */}
            {({ loading }) => (loading ? componentText.loading : componentText.download)}
          </PDFDownloadLink>
        </Button>
        <Button
          sx={{ float: 'right', width: '30%', mr: '1rem' }}
          variant='contained'
          color='secondary'
          onClick={() => handleClose()}
        >
          <b>{componentText.closeButton}</b>
        </Button>
      </Box>
    </DodgeModal>
  );
};

export default PreviewReleaseNotes;
