import loadable from '@loadable/component';
import { Redirect, Route, Switch } from 'react-router-dom';

// **** layouts ****
import { MainLayout, OnboardingLayout } from '../components/Layouts';
import { CookiesLayout } from '../components/Layouts/CookiesLayout';
import { MaintenanceLayout } from '../components/Layouts/MaintenanceLayout';
// **** auth checks ****
import AuthRequired from 'auth/components/AuthRequired';
import PlantRequired from 'auth/components/PlantRequired';
import { usePageTracking } from 'helpers/usePageTracking';

// **** pages *****

import SensorsInfoLayout from 'features/s-redirection/Components/SensorsInfoLayout';

// Page not found

const PageNotFoundPage = loadable(
  async () => await import('features/error-redirection/pages/PageNotFoundPage'),
);

// onboarding
const WelcomePage = loadable(async () => await import('features/onboarding/pages/WelcomePage'));

const InvitationPage = loadable(
  async () => await import('features/onboarding/pages/InvitationPage'),
);

const JoinPlantPage = loadable(async () => await import('features/onboarding/pages/JoinPlantPage'));

const CreatePlantPage = loadable(
  async () => await import('features/onboarding/pages/CreatePlantPage'),
);

const Pending = loadable(async () => await import('features/onboarding/pages/PendingPage'));

// plant
const DashboardPage = loadable(async () => await import('features/dashboard/pages/DashboardPage'));

const AssetsManagementPage = loadable(
  async () => await import('features/assets-management/pages/AssetsManagementPage'),
);

const PlantsManagementPage = loadable(
  async () => await import('features/plants-management/pages/PlantsManagementPage'),
);

const GatewaysManagementPage = loadable(
  async () => await import('features/gateways-management/pages/GatewaysManagementPage'),
);

const ReportsManagementPage = loadable(
  async () => await import('features/reports-management/pages/ReportsManagementPage'),
);

const CartridgePage = loadable(
  async () => await import('features/cartridges-management/pages/CartridgePage'),
);

const ChannelPartnersPage = loadable(
  async () => await import('features/channel-partners/pages/ChannelPartnersPage'),
);

const CookiesNotice = loadable(
  async () => await import('components/CookiePage/pages/CookiesNoticePage'),
);

const LicenceArgeement = loadable(
  async () => await import('components/CookiePage/pages/LicenceArgeementPage'),
);

const SecurityFile = loadable(async () => await import('components/Security/SecurityFileLink'));
const GpgKeyFile = loadable(async () => await import('components/Security/GpgKeyFileLink'));
const Maintenance = loadable(async () => await import('features/maintenance/MaintenanceScreen'));

// Sensor redirections
const SensorsInfoPage = loadable(
  async () => await import('features/s-redirection/pages/SensorInfoPage'),
);

const MobileRedirectionPage = loadable(
  async () => await import('features/s-redirection/pages/MobileRedirectionPage'),
);

export default function Routes() {
  usePageTracking();

  return (
    <Switch>
      {/* Onboarding */}
      <Route exact path={['/onboarding/*']}>
        <AuthRequired>
          <OnboardingLayout>
            <Route exact path='/onboarding/Pending' component={Pending} key='Pending' />
            <Route exact path='/onboarding/welcome' component={WelcomePage} key='Welcome3' />
            <Route
              exact
              path='/onboarding/create-plant'
              component={CreatePlantPage}
              key='CreatePlant'
            />
            <Route exact path='/onboarding/join-plant' component={JoinPlantPage} key='JoinPlant' />
            <Route
              exact
              path='/onboarding/invitation'
              component={InvitationPage}
              key='Invitation'
            />
          </OnboardingLayout>
        </AuthRequired>
      </Route>
      {/*cookies */}
      <Route exact path={'/privacy-and-cookie-notice'}>
        <CookiesLayout>
          <Route exact path='/privacy-and-cookie-notice' component={CookiesNotice} key='modal' />
        </CookiesLayout>
      </Route>
      <Route exact path={'/cookies/privacy-and-cookie-notice'}>
        <Redirect to='/privacy-and-cookie-notice' />
      </Route>
      <Route exact path={'/cookies/privacy-and-cookies-notice'}>
        <Redirect to='/privacy-and-cookie-notice' />
      </Route>
      <Route exact path={'/end-user-license-agreement'}>
        <CookiesLayout>
          <Route
            exact
            path='/end-user-license-agreement'
            component={LicenceArgeement}
            key='modal'
          />
        </CookiesLayout>
      </Route>
      <Route exact path={'/cookies/end-user-license-agreement'}>
        <Redirect to='/end-user-license-agreement' />
      </Route>
      {/* Plant */}
      <Route
        exact
        path={(() => {
          const paths = [
            '/',
            '/dashboard',
            '/gw',
            '/gw/*',
            '/preferences',
            '/assets-management',
            '/plants-management',
            '/plants-management/*',
            '/gateways-management',
            '/reports-management',
            '/cartridges-management',
            '/channel-partners',
          ];
          return paths;
        })()}
      >
        <AuthRequired>
          <PlantRequired>
            <MainLayout>
              {/* Dashboard */}
              <Route exact path='/' component={DashboardPage} key='mainPage' />
              <Route exact path='/dashboard' component={DashboardPage} key='dashboardPage' />
              <Route
                exact
                path='/preferences'
                component={DashboardPage}
                key='dashboardPagePreferences'
              />
              <Route path='/gw' component={DashboardPage} key='dashboardGW' />
              {/* Asset management */}
              <Route
                exact
                path='/assets-management'
                component={AssetsManagementPage}
                key='assetsManagementPage'
              />
              {/* Plant management */}
              <Route
                path='/plants-management'
                component={PlantsManagementPage}
                key='plantsManagementPage'
              />
              {/* Gateway management */}
              <Route
                exact
                path='/gateways-management'
                component={GatewaysManagementPage}
                key='gatewaysManagementPage'
              />
              <Route
                exact
                path='/cartridges-management'
                component={CartridgePage}
                key='cartridgesManagementPage'
              />
              <Route
                exact
                path='/channel-partners'
                component={ChannelPartnersPage}
                key='channelPartnersPage'
              />
              {/* Reports management */}
              <Route
                exact
                path='/reports-management'
                component={ReportsManagementPage}
                key='reportsManagementPage'
              />
            </MainLayout>
          </PlantRequired>
        </AuthRequired>
      </Route>
      <Route path='/.well-known/gpg_key.txt' component={GpgKeyFile} />
      <Route path='/.well-known/security.txt' component={SecurityFile} />
      <Route exact path={'/maintenance'}>
        <MaintenanceLayout>
          <Route exact path='/maintenance' component={Maintenance} key='Maintenance' />
        </MaintenanceLayout>
      </Route>
      <Route exact path={'/s'}>
        <SensorsInfoLayout>
          <Route exact path='/s' component={SensorsInfoPage} />
        </SensorsInfoLayout>
      </Route>
      <Route exact path={'/mobile-redirect'}>
        <SensorsInfoLayout>
          <Route exact path='/mobile-redirect' component={MobileRedirectionPage} />
        </SensorsInfoLayout>
      </Route>
      <Route component={PageNotFoundPage} key='pageNotFoundPage' />
    </Switch>
  );
}
